body {
  font-family: "arial", sans-serif;
}

header {
  background-color: #333333;
  color: #ffffff;
  margin-bottom: 10px;
}

header .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

header #nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5em;
}

.container {
  max-height: 80vh;
}

.btn:disabled {
  opacity: 0.2;
}

#file-table {
  max-height: 60vh;
}

#file-table table {
  max-height: 100%;
}
