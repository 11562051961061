.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 300px;
  border: 2px dashed #ccc;
  color: #ccc;
  text-align: center;
  cursor: pointer;
}

.dropzone.dragover {
  border-color: #000;
  color: #000;
}
